// src/components/UserReviews.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { Rate, Card } from 'antd';
import moment from 'moment';

const UserReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const q = query(collection(db, 'reviews'), where('approved', '==', true));
      const querySnapshot = await getDocs(q);
      const reviewsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setReviews(reviewsData);
    };

    fetchReviews();
  }, []);

  const maxLength = 300; // Set a character limit for reviews

  return (
    <div className="reviews-container">
      {reviews.map((review) => (
        <Card key={review.id} className="review-card">
          <h3>{review.name}</h3>
          <Rate disabled value={review.rating} />
          <p>{moment(review.created.toDate()).format('YYYY-MM-DD HH:mm')}</p>
          <p>
            {review.review.length > maxLength
              ? `${review.review.substring(0, maxLength)}...`
              : review.review}
          </p>
        </Card>
      ))}
    </div>
  );
};

export default UserReviews;
