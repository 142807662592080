import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import TopBar from './TopBar';
import WelcomeScreen from './WelcomeScreen';
import ExamplePage from './ExamplePage';
import FaqPage from './FaqPage';
import ChatTab from './ChatTab';
import EmergencyPage from './EmergencyPage';
import AboutUs from './AboutUs';
import ContactPage from './ContactPage';
import AdminPanel from './AdminPanel';
import OrderHistory from './OrderHistory';
import DailyCheckin from './MyPet';
import Login from './Login';
import Register from './Register';
import ClinicPreview from './ClinicPreview';
import { AuthContext } from './AuthContext';

const { Content } = Layout;

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (initialLoad) {
      const fadeOutTimer = setTimeout(() => {
        setLoading(false);
      }, 2500);

      const navigateTimer = setTimeout(() => {
        setInitialLoad(false);
        navigate('/');
      }, 2900);

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(navigateTimer);
      };
    }
  }, [initialLoad, navigate]);

  return (
    <Layout className="layout">
      <TopBar />
      <Content>
        {loading && initialLoad ? (
          <WelcomeScreen />
        ) : (
          <Routes>
            <Route path="/" element={<EmergencyPage />} />
          </Routes>
        )}
        {!loading && (
          <footer className="footer">
            <div className="copyright">
              &copy; 2024 Cania Limited. All rights reserved.
            </div>
          </footer>
        )}
      </Content>
    </Layout>
  );
}

export default App;