// ContactPage.js
import React from 'react';
import { Typography, Card } from 'antd';
import './ContactPage.css';

const { Title, Text } = Typography;

const ContactPage = () => {
  return (
    <div className="contact-container">
      <Title className="title">Contact Us</Title>
      <div className="contact-section">
        <Card className="contact-card">
          <Title level={4}>Adrese de email</Title>
          <Title level={4}> CTO - stefan.tutu@cania.ro</Title>
          <Title level={4}> Support - support@cania.ro</Title>
          <Title level={4}> CEO - stefan.bicoi@cania.ro</Title>
          <Title level={4}>   Q&A - info@cania.ro</Title>
        </Card>
      </div>
    </div>
  );
};

export default ContactPage;
