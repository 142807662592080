import React from 'react';
import { Layout } from 'antd';
import Welcome from './Welcome';
import Chatbox from './ChatBox';
import Message from './Message';
import SendMessage from './SendMessage';

const { Content } = Layout;

const ChatTab = () => {
  return (
    <Content>
      <Welcome />
      <Chatbox>
        {/* Messages go here */}
        <Message />
      </Chatbox>
      <SendMessage />
    </Content>
  );
};

export default ChatTab;
