import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select } from 'antd';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import './auth.css';

const { Option } = Select;

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const firestore = getFirestore();

  const onFinish = async (values) => {
    // This function will not be called since the form is disabled
  };

  return (
    <div className="auth-container register-container">
      <h1>Register</h1>
      <Form name="register" onFinish={onFinish} layout="vertical">
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input className="disabled-field" placeholder="Email" disabled />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password className="disabled-field" placeholder="Password" disabled />
        </Form.Item>
        <Form.Item
          name="pet_age"
          rules={[{ required: true, message: 'Please input your Pet\'s Age!' }]}
        >
          <Input className="disabled-field" placeholder="Pet Age" disabled />
        </Form.Item>
        <Form.Item
          name="pet_type"
          rules={[{ required: true, message: 'Please select your Pet\'s Type!' }]}
        >
          <Select className="disabled-field" placeholder="Select Pet Type" disabled>
            <Option value="dog">Dog</Option>
            <Option value="cat">Cat</Option>
            <Option value="parrot">Parrot</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block disabled className="disabled-button">
            Register
          </Button>
        </Form.Item>
      </Form>
      <Button type="link" onClick={() => navigate('/login')} block className="disabled-button">
        Login
      </Button>
      <Button type="link" onClick={() => {
        sessionStorage.setItem('guest', 'true');
        navigate('/');
      }} block>
        Continue as Guest
      </Button>
    </div>
  );
};

export default Register;
