import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const OrderHistory = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ margin: '16px' }}>
        <div style={{ padding: 24, minHeight: 360, background: '#fff' }}>
          <h1>Order History</h1>
          {/* Add your order history content here */}
        </div>
      </Content>
    </Layout>
  );
};

export default OrderHistory;
