import React, { useState, useEffect, useRef } from 'react';
import { Typography, Card, Row, Col, Button, Avatar, Rate } from 'antd';
import { EnvironmentOutlined, DownOutlined, MessageOutlined } from '@ant-design/icons';
import './ExamplePage.css';
import siavet from './siavet.jpg';
import winnerplus from './winnerplus.jpg';
import emergencyButton from './emergency-button.gif';
import stefanbicoi from './Photo4.png'; // Import the image file
import silionbogdan from './Photo55.jpg'; // Import the image file
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Chat from './chat'; // Import the Chat component
import drradulescu from './img/radu.png';
import melodin from './img/melodin.png';
import './App.css';

const { Title, Text } = Typography;

const testimonials = [
  {
    avatar: stefanbicoi,
    name: "Bicoi Stefan (CEO)",
    rating: 4,
    comment: "The concept behind Cania is revolutionary for pet owners. It's something that the market truly needs.",
  },
  {
    avatar: silionbogdan,
    name: "Bogdan Silion",
    rating: 5,
    comment: "Seeing the business plan for Cania made me believe in the potential of tech in pet care. Excited to see it launch!",
  }
];

function Testimonials({ showTestimonials }) {
  return (
    <div className={`testimonials-section ${showTestimonials ? 'is-visible' : ''}`}>
      <Title level={3}>Ce spun Romanii ? :)</Title>
      <Row gutter={[16, 16]}>
        {testimonials.map((testimonial, index) => (
          <Col key={index} xs={24} sm={12} md={8}>
            <Card className="testimonial-item">
              <Avatar src={testimonial.avatar} size={64} />
              <Rate disabled defaultValue={testimonial.rating} />
              <blockquote>"{testimonial.comment}"</blockquote>
              <p>- {testimonial.name}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

function ExampleBox1() {
  const googleMapsURLSiavet = "https://maps.app.goo.gl/uU6pUkNUz4JTk3nG9";
  return (
    <Card className="example-box" bordered={false}>
      <img className="example-image" src={siavet} alt="Siavet"/>
      <div className="example-text-content">
        <Title level={4}>Dr. Silvia Aftene</Title>
        <Text>Clinica veterinara, centru de diagnostic si tratament, radiologie veterinara, urgente veterinare in afara orelor de program</Text>
        <div className="info-container">
          <Button type="primary" className="info-button">Mai multe informatii</Button>
          <a href={googleMapsURLSiavet} target="_blank" rel="noopener noreferrer">
            <EnvironmentOutlined className="google-maps-icon" />
          </a>
        </div>
      </div>
    </Card>
  );
}

function ExampleBox2() {
  const googleMapsURLWinnerPlus = "https://maps.app.goo.gl/zcrvMYREtuLLtvfDA";
  return (
    <Card className="example-box" bordered={false}>
      <img className="example-image" src={winnerplus} alt="Winner Plus"/>
      <div className="example-text-content">
        <Title level={4}>Winner Plus Focsani</Title>
        <Text>Mancare super premium(made in Germany) la cel mai mic pret din Romania . Super oferte Ex. 20 % discount in favoarea clientului ! VA ASTEPTAM</Text>
        <div className="info-container">
          <Button type="primary" className="info-button">Mai multe informatii</Button>
          <a href={googleMapsURLWinnerPlus} target="_blank" rel="noopener noreferrer">
            <EnvironmentOutlined className="google-maps-icon" />
          </a>
        </div>
      </div>
    </Card>
  );
}

function ExampleBox3() {
  const googleMapsURLDrRadulescu = "https://maps.app.goo.gl/JVAKE1kZQddb3bAi6";
  return (
    <Card className="example-box" bordered={false}>
      <img className="example-image" src={drradulescu} alt="Emergency Junior Vets"/>
      <div className="example-text-content">
        <Title level={4}>Emergency Junior Vets</Title>
        <Text>Servicii de urgenta pentru animale de companie si transport cu ambulanta . DR. Florin Radulescu</Text>
        <div className="info-container">
          <Button type="primary" className="info-button">Mai multe informatii</Button>
          <a href={googleMapsURLDrRadulescu} target="_blank" rel="noopener noreferrer">
            <EnvironmentOutlined className="google-maps-icon" />
          </a>
        </div>
      </div>
    </Card>
  );
}

function ExampleBox4() {
  const googleMapsURLMelodin = "https://maps.app.goo.gl/bbh6nmRh18BxWaZ88";
  return (
    <Card className="example-box" bordered={false}>
      <img className="example-image" src={melodin} alt="Winner Plus"/>
      <div className="example-text-content">
        <Title level={4}>Melodin - Cabinet Si Farmacie Veterinara</Title>
        <Text>Clinica Melodin - Aducem zambete si bucurii animalutelor si stapanilor lor.</Text>
        <div className="info-container">
          <Button type="primary" className="info-button">Mai multe informatii</Button>
          <a href={googleMapsURLMelodin} target="_blank" rel="noopener noreferrer">
            <EnvironmentOutlined className="google-maps-icon" />
          </a>
        </div>
      </div>
    </Card>
  );
}

function ExamplePage() {
  const [isVisible, setIsVisible] = useState(false);
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false); // State to manage chat visibility
  const [buttonText, setButtonText] = useState('Apel de Urgență'); // State to manage button text
  const testimonialsRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            setTimeout(() => setShowTestimonials(true), 3500);
          } else {
            setIsVisible(false);
            setShowTestimonials(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const scrollToContent = () => {
    setIsVisible(true);
    setShowTestimonials(true);
    const section = document.querySelector('.testimonials-section');
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const toggleChatVisibility = () => {
    setIsChatVisible(prevState => !prevState); // Toggle chat visibility
  };

  const handleEmergencyClick = () => {
    setButtonText('Asteapta 2 secunde ...');
    setTimeout(() => {
      navigate('/emergency');
    }, 2000); // Delay of 2 seconds before navigating
  };

  return (
    <div className="example-page">
      <div className="typewriter">
        <Title level={2} className="typewriter-text">Cania</Title>
      </div>
      <div className="emergency-section">
        <img src={emergencyButton} alt="Emergency Button" className="emergency-button" />
        <div className="emergency-text">
          <Title level={3}>Apel de Urgență</Title>
          <Text>Principalul nostru selling-point este apelul de urgenta care iti ia locatia precizata la inregistrarea contului (sau in cazul in care un cont nu este gasit , locatia telefonului) si o transmite callcentereului nostru , deschizand un apel cu unul dintre reprezentanti</Text>
          <Button type="primary" className="flash-button" onClick={handleEmergencyClick}>{buttonText}</Button>
          </div>
      </div>
      <Text className="spacing-text">Primii nostrii parteneri care au avut incredere in visul nostru sunt SiaVet Galati si Winner Plus Focsani</Text>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}><ExampleBox1 /></Col>
        <Col xs={24} sm={12} md={8}><ExampleBox2 /></Col>
        <Col xs={24} sm={12} md={8}><ExampleBox3 /></Col>
        <Col xs={24} sm={12} md={8}><ExampleBox4 /></Col>
      </Row>
      <Button type="primary" shape="circle" icon={<DownOutlined />} className="scroll-down-button" onClick={scrollToContent} />
      <div 
        ref={testimonialsRef} 
        className={`content-container ${isVisible ? 'is-visible' : ''}`}
      >
        {isVisible && <Testimonials showTestimonials={showTestimonials} />}
      </div>
      {isChatVisible && <Chat />} {/* Render Chat component */}
      <Button type="primary" shape="circle" icon={<MessageOutlined />} className="chat-icon" onClick={toggleChatVisibility} /> {/* Chat icon */}
    </div>
  );
}

export default ExamplePage;
