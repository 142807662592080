// AdminPanel.js

import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Import the auth object from firebaseConfig
import LoginForm from './LoginForm'; // Import LoginForm component

const { Content, Sider } = Layout;

const AdminPanel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Define loading state
  const [user, setUser] = useState(null); // Define user state

  useEffect(() => {
    // Subscribe to authentication state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user); // Update user state with current user
      setLoading(false); // Set loading to false when authentication state is initialized
    });

    return () => {
      unsubscribe(); // Cleanup subscription
    };
  }, []);

  // Handle logout
  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the user
      navigate('/admin-panel/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Render loading state while authentication state is being initialized
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render login form if user is not logged in
  if (!user) {
    return <LoginForm onSuccess={() => navigate('/admin-panel')} />; // Redirect to admin panel after successful login
  }

  // Render content once authentication state is initialized and user is logged in
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          <Menu.Item key="1">
            <Link to="/admin-panel">Home</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/admin-panel/history">History</Link>
          </Menu.Item>
        </Menu>
        <Button type="primary" onClick={handleLogout}>Logout</Button> {/* Logout button */}
      </Sider>
      <Layout>
        <Content style={{ margin: '16px' }}>
          {/* Content of the admin panel */}
          <div style={{ padding: 24, minHeight: 360, background: '#fff' }}>
            {/* Here you can add the components and functionality for managing orders */}
            <h1>Admin Panel</h1>
            {/* Add your order management components here */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminPanel;
