import React, { useState } from 'react';
import SearchBar from './SearchBar';
import InteractiveContent from './InteractiveContent';

function FAQPage() {
  const [searchResults, setSearchResults] = useState([]);
  const [faqData] = useState([
    { question: 'Ce este cania?', answer: 'Cania este un webapp care serveste omenirea cu un serviciu de urgenta si prosibilitatea programarilor online' },
    { question: 'Care e cea mai intalnita afectiune ?', answer: 'Pentu un exemplu vom avea cuvintere curge si sange .(pentru a demonstra funcitonalitatea searchbar-ului)' },
    // Add more FAQ data as needed
  ]);

  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleSearch = (query) => {
    // Perform search functionality here
    // For simplicity, let's just filter faqData based on query
    const results = faqData.filter(
      (faq) =>
        faq.question.toLowerCase().includes(query.toLowerCase()) ||
        faq.answer.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(results);
    setShowSearchResults(true);
  };

  const handleBackToFAQ = () => {
    setSearchResults([]);
    setShowSearchResults(false);
  };

  return (
    <div className="faq-page" style={{ backgroundColor: '#fefbf5', padding: '20px' }}>
      <h1>FAQs</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <SearchBar onSearch={handleSearch} />
        {showSearchResults && (
          <button onClick={handleBackToFAQ} style={backButtonStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M9.8 12l7.1-7.1c.6-.6.6-1.6 0-2.1-.6-.6-1.6-.6-2.1 0l-8 8c-.6.6-.6 1.6 0 2.1l8 8c.6.6 1.6.6 2.1 0 .6-.6.6-1.6 0-2.1L9.8 12z"/>
            </svg>
          </button>
        )}
      </div>

      {showSearchResults ? (
        <div>
          <h2>Search Results</h2>
          <InteractiveContent data={searchResults} />
        </div>
      ) : (
        <div>
          <h2>FAQs</h2>
          <InteractiveContent data={faqData} />
        </div>
      )}
    </div>
  );
}

const backButtonStyle = {
  borderRadius: '50%',
  width: '60px', // Increased size
  height: '60px', // Increased size
  background: 'linear-gradient(135deg, rgba(218, 232, 247, 0.8) 0%, rgba(214, 229, 247, 0.8) 100%)', // Added transparency
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  border: '4px solid rgba(62, 144, 255, 0.8)', // Adjusted border color and transparency
  animation: 'bounce 0.8s ease infinite', // Applied continuous bounce animation
};

export default FAQPage;
