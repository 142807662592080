import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import getFirestore function

const firebaseConfig = {
    apiKey: "AIzaSyBwjAvUU48kwvJjgU8ZlhMHm76-uz7pLCU",
    authDomain: "njcuai.firebaseapp.com",
    projectId: "njcuai",
    storageBucket: "njcuai.appspot.com",
    messagingSenderId: "216778001714",
    appId: "1:216778001714:web:d02ad92f0a9d22574f9b2b"};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore and assign it to the db object

export { app, auth, db };
