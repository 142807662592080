import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        const guest = sessionStorage.getItem('guest');
        if (guest) {
          setUser({ guest: true });
        } else {
          setUser(null);
        }
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const logout = () => {
    if (user && user.guest) {
      sessionStorage.removeItem('guest');
      setUser(null);
    } else {
      auth.signOut().then(() => {
        setUser(null);
      });
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
