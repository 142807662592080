import React, { useEffect, useState } from 'react';
import './WelcomeScreen.css';
import logo from './img/testlogo111.png'; // You can replace this with logo.gif if needed

function WelcomeScreen() {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false); // Trigger the fade-out
        }, 2500); // Trigger fade-out slightly before navigation

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`welcome-screen ${!isVisible ? "hidden" : ""}`}>
            <img src={logo} alt="Logo" className="fade-in-logo" />
        </div>
    );
}

export default WelcomeScreen;
