import React from 'react';
import { Typography, Row, Col } from 'antd';
import './aboutus.css';
import dog from './dog-poster.png';
import paw from './pawss.png';
import valori from './valori.png';
import team from './team.png';
const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <div className="page-container">
      <div className="content">
        <Title className="title">About Us</Title>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} md={12}>
            <div className="about-section">
              <img src={dog} alt="Image 1" className="about-image" />
              <div className="about-text">
                <Title level={3}>Our Mission</Title>
                <Paragraph>
                Misiunea noastră la CANIA este să aducem o schimbare pozitivă în viața animalelor de companie și a proprietarilor lor prin furnizarea unui acces ușor și rapid la servicii veterinare de urgență. Ne angajăm să oferim îngrijire promptă și de calitate pentru fiecare animal care are nevoie de ajutor în momentele critice, consolidând legătura dintre animale și stăpânii lor și asigurându-ne că niciun companion necuvântător nu este lăsat în suferință. 
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="about-section">
              <img src={paw} alt="Image 2" className="about-image" />
              <div className="about-text">
                <Title level={3}>Our Vision</Title>
                <Paragraph>
                Viziunea noastră la CANIA este să devenim lideri în domeniul serviciilor veterinare de urgență, redefinind standardele de îngrijire a animalelor de companie în România și pe plan global. Ne propunem să dezvoltăm și să implementăm tehnologii inovatoare care să permită proprietarilor de animale să ofere asistență imediată și eficientă pentru tovarășii lor necuvântători, contribuind astfel la o comunitate mai sănătoasă și mai empatică.
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="about-section">
              <img src={valori} alt="Image 3" className="about-image" />
              <div className="about-text">
                <Title level={3}>Our Values</Title>
                <Paragraph>
                Valorile noastre sunt fundamentale pentru tot ceea ce facem la CANIA. Ne ghidăm activitatea după principii precum compasiunea, integritatea și responsabilitatea. Ne străduim să fim deschiși și transparenți în relația cu clienții și partenerii noștri, și să ne asigurăm că fiecare decizie pe care o luăm este în interesul bunăstării animalelor și al comunității.
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="about-section">
              <img src={team} alt="Image 4" className="about-image" />
              <div className="about-text">
                <Title level={3}>Our Team</Title>
                <Paragraph>
                Echipa noastră la CANIA este formată din profesioniști pasionați și dedicați, cu o vastă experiență în domeniile veterinariei, tehnologiei și afacerilor. Suntem o echipă diversificată și colaborativă, care își unește forțele pentru a inova și a aduce schimbări pozitive în industria veterinară. Fiecare membru aduce cu sine expertiză și perspectivă unică, contribuind la succesul și creșterea continuă a companiei noastre.
                </Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
