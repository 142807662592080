// src/components/ReviewForm.js
import React, { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import './emergencycss.css'; // Ensure this is correctly imported

import { Rate, Button, Input, Form } from 'antd';

const { TextArea } = Input;

const ReviewForm = () => {
  const [name, setName] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lastSubmission = localStorage.getItem('lastSubmission');
    const now = new Date().getTime();

    if (lastSubmission && now - lastSubmission < 60000) { // 1 minute cooldown
      setMessage('You can only submit one review per minute.');
      return;
    }

    if (review.length > 300) {
      setMessage('Review cannot exceed 300 characters.');
      return;
    }

    try {
      await addDoc(collection(db, 'reviews'), {
        name,
        review,
        rating,
        approved: false, // All new reviews are unapproved by default
        created: Timestamp.now(),
      });
      localStorage.setItem('lastSubmission', now);
      setMessage('Review submitted successfully!');
      setName('');
      setReview('');
      setRating(0);
    } catch (error) {
      setMessage('Error submitting review: ' + error.message);
    }
  };

  return (
    <div className="review-form">
      <h2>Leave a Review</h2>
      <Form onSubmitCapture={handleSubmit}>
        <Form.Item label="Name">
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            size="large"
          />
        </Form.Item>
        <Form.Item label="Review">
          <TextArea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            maxLength={300}
            autoSize={{ minRows: 5, maxRows: 10 }}
            required
          />
        </Form.Item>
        <Form.Item label="Rating">
          <Rate
            value={rating}
            onChange={(value) => setRating(value)}
            required
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">Submit</Button>
        </Form.Item>
      </Form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ReviewForm;
