import './Chat.css'; // Import CSS for styling
import React, { useState } from 'react';
import { MessageOutlined, ArrowLeftOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons'; // Import icons from Ant Design

function Chat() {
  const [isOpen, setIsOpen] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setMessage(''); // Clear message input when opening/closing chat
  };

  const toggleConversations = () => {
    setShowConversations(!showConversations);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      // Create a timestamp for the message
      const timestamp = new Date().toLocaleTimeString();
      // Create a new message object with the text, timestamp, and sender
      const newMessage = {
        text: message,
        timestamp: timestamp,
        sender: 'user' // For now, assume the user is the sender
      };
      // Add the new message to the beginning of the messages array
      setMessages([newMessage, ...messages]);
      // Add the new message to the conversation history
      setConversationHistory([newMessage, ...conversationHistory]);
      // Clear message input after sending
      setMessage('');
    }
  };

  const handleHistoryItemClick = (conversation) => {
    setCurrentConversation(conversation);
    setShowConversations(false); // Hide conversation list
  };

  return (
    <div className="chat">
      {/* Chat icon */}
      {!isOpen && (
        <div className="chat-toggle" onClick={toggleChat}>
          <div className="chat-icon-container">
            <MessageOutlined className="chat-icon" />
          </div>
        </div>
      )}
      {/* Chat panel */}
      {isOpen && (
        <div className="chat-panel">
          <div className="chat-header">
            {/* Back icon to close the chat */}
            <ArrowLeftOutlined className="back-icon" onClick={toggleChat} />
            {/* History icon */}
            <HistoryOutlined className="history-icon" onClick={toggleConversations} />
          </div>
          <div className="chat-body">
            {currentConversation ? (
              // Render selected conversation messages here
              <div className="message-list">
                {currentConversation.messages.map((msg, index) => (
                  <div key={index} className="message">
                    <UserOutlined className="account-icon" />
                    <div className="message-text">{msg.text}</div>
                    <div className="message-timestamp">{msg.timestamp}</div>
                  </div>
                ))}
              </div>
            ) : (
              // Render chat messages here
              <div className="message-list">
                {messages.map((msg, index) => (
                  <div key={index} className="message">
                    <UserOutlined className="account-icon" />
                    <div className="message-text">{msg.text}</div>
                    <div className="message-timestamp">{msg.timestamp}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="messageBox">
            <div className="fileUploadWrapper">
              <label htmlFor="file">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
                  <circle
                    strokeWidth="20"
                    stroke="#6c6c6c"
                    fill="none"
                    r="158.5"
                    cy="168.5"
                    cx="168.5"
                  ></circle>
                  <path
                    strokeLinecap="round"
                    strokeWidth="25"
                    stroke="#6c6c6c"
                    d="M167.759 79V259"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeWidth="25"
                    stroke="#6c6c6c"
                    d="M79 167.138H259"
                  ></path>
                </svg>
                <span className="tooltip">Add an image</span>
              </label>
              <input type="file" id="file" name="file" />
            </div>
            <input
              required
              placeholder="Message..."
              type="text"
              id="messageInput"
              value={message}
              onChange={handleMessageChange}
            />
            <button id="sendButton" onClick={handleSendMessage}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                <path
                  fill="none"
                  d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                ></path>
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="33.67"
                  stroke="#6c6c6c"
                  ></path>
                  </svg>
                  </button>
                  </div>
                  </div>
                  )}
                  {/* Conversation History window */}
                  {showConversations && (
                  <div className="conversation-history-window">
                  <div className="history-header">
                  <h3>Conversation History</h3>
                  </div>
                  <div className="conversation-list">
                  {conversationHistory.map((conversation, index) => (
                  <div key={index} className="conversation-item" onClick={() => handleHistoryItemClick(conversation)}>
                  <span>Convo #{index + 1}</span>
                  </div>
                  ))}
                  </div>
                  </div>
                  )}
                  </div>
                  );
                  }
                  
                  export default Chat;
