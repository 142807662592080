import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Button, message } from 'antd';
import baseimage from './img/base.png';
import taskone from './img/taskone.png';
import tasktwo from './img/tasktwo.png';
import taskthree from './img/taskthree.png';
import dogImage from './img/dog_image.png';
import catImage from './img/cat_image.png';
import parrotImage from './img/parrot_image.png';
import './mypet.css';

// Initialize Firebase (use your own config)
const firebaseConfig = {
  apiKey: "AIzaSyBwjAvUU48kwvJjgU8ZlhMHm76-uz7pLCU",
  authDomain: "njcuai.firebaseapp.com",
  projectId: "njcuai",
  storageBucket: "njcuai.appspot.com",
  messagingSenderId: "216778001714",
  appId: "1:216778001714:web:d02ad92f0a9d22574f9b2b"
};

if (!getApps().length) {
  initializeApp(firebaseConfig);
}

const DailyCheckin = () => {
  const [tasks, setTasks] = useState([false, false, false]);
  const [user, setUser] = useState(null);
  const [petInfo, setPetInfo] = useState({ type: '', age: 0 });
  const [showFunFact, setShowFunFact] = useState(false);
  const [funFact, setFunFact] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();

  const taskList = [
    'Feed your pet',
    'Pet your pet',
    'Take your pet for a walk',
    'Play with your pet',
    'Give your pet a treat',
    'Brush your pet',
    'Teach your pet a new trick',
    'Check your pet’s health',
    'Clean your pet’s area',
    'Give your pet fresh water'
  ];

  const funFacts = {
    dog: "Did you know? Dogs have a sense of time. It's been proven that they know the difference between one hour and five.",
    cat: "Did you know? Cats have five toes on their front paws, but only four toes on their back paws.",
    parrot: "Did you know? Parrots can learn to mimic sounds and words. Some can even understand basic concepts."
  };

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const guest = sessionStorage.getItem('guest');

    if (guest) {
      setUser({ guest: true });
    } else {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          try {
            const userDoc = doc(firestore, 'admin', `created${user.uid}`);
            const docSnap = await getDoc(userDoc);
            if (docSnap.exists()) {
              const data = docSnap.data();
              const petType = data.pet_type.toLowerCase();
              setPetInfo({ type: petType, age: data.pet_age });
              setSubmitDisabled(data.taskstatus || false);
              setFunFact(funFacts[petType]);
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error("Error fetching document:", error);
          }
        } else {
          navigate('/login');
        }
      });
    }

    const storedTime = localStorage.getItem('taskTimer');
    if (storedTime) {
      const timeDifference = Date.now() - new Date(storedTime).getTime();
      if (timeDifference <= 86400000) {
        setSubmitDisabled(true);
        setTimer(86400000 - timeDifference);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (timer) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1000) {
            clearInterval(interval);
            resetTasks();
            return null;
          }
          return prevTimer - 1000;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleTaskCompletion = (index) => {
    const newTasks = [...tasks];
    newTasks[index] = !newTasks[index];
    setTasks(newTasks);
  };

  const handleSubmit = async () => {
    if (tasks.every(task => task)) {
      message.success('Tasks completed! New tasks will be available in 24 hours.');
      setSubmitDisabled(true);
      setTimer(86400000);
      localStorage.setItem('taskTimer', new Date().toString());

      const auth = getAuth();
      const firestore = getFirestore();
      const user = auth.currentUser;
      const userDoc = doc(firestore, 'admin', `created${user.uid}`);

      try {
        await updateDoc(userDoc, {
          taskstatus: true
        });
      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      message.warning('Please complete all tasks before submitting.');
    }
  };

  const resetTasks = async () => {
    setTasks([false, false, false]);
    setSubmitDisabled(false);
    message.info('New tasks are available!');

    const auth = getAuth();
    const firestore = getFirestore();
    const user = auth.currentUser;
    const userDoc = doc(firestore, 'admin', `created${user.uid}`);

    try {
      await updateDoc(userDoc, {
        taskstatus: false
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const taskImages = [
    baseimage,
    taskone,
    tasktwo,
    taskthree
  ];

  const petImages = {
    dog: dogImage,
    cat: catImage,
    parrot: parrotImage,
    default: 'default_pet_image.jpg'
  };

  const petImage = petImages[petInfo.type] || petImages.default;

  if (user && user.guest) {
    return (
      <div className="daily-checkin">
        <h1>Daily Check-in</h1>
        <p>You are not logged in yet. <Button type="link" onClick={() => navigate('/login')}>Login</Button></p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="daily-checkin">
        <h1>Daily Check-in</h1>
        <p>You are not logged in yet. <Button type="link" onClick={() => navigate('/login')}>Login</Button></p>
      </div>
    );
  }

  return (
    <div className="daily-checkin">
      <h1>Daily Check-in</h1>
      <div className="tasks">
        {tasks.map((task, index) => (
          <div key={index} className="task">
            <input
              type="checkbox"
              checked={task}
              onChange={() => handleTaskCompletion(index)}
              disabled={submitDisabled}
            />
            {taskList[index]}
          </div>
        ))}
      </div>
      <Button 
        type="primary" 
        onClick={handleSubmit} 
        disabled={submitDisabled || !tasks.every(task => task)}
      >
        Submit Tasks
      </Button>
      {submitDisabled && timer && (
        <div className="timer">
          New tasks will be available in: {formatTime(timer)}
        </div>
      )}
      <div className="task-image">
        <img src={taskImages[tasks.filter(Boolean).length]} alt="Task state" />
      </div>
      <div className="pet-info">
        <h2>Pet Information</h2>
        <p>Pet Type: {petInfo.type.charAt(0).toUpperCase() + petInfo.type.slice(1)}</p>
        <p>Pet Age: {petInfo.age}</p>
        <img src={petImage} alt="Pet" />
      </div>
      <div className="fun-fact">
        <h2>Fun Fact</h2>
        <div className="fun-fact-cover" onClick={() => setShowFunFact(true)}>
          <h3>Click to reveal today's fun fact!</h3>
        </div>
        {showFunFact && (
          <div className="fun-fact-detail">
            <p>{funFact}</p>
            <Button onClick={() => setShowFunFact(false)}>Close</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyCheckin;
