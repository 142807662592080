// src/EmergencyPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Checkbox } from 'antd';
import { PhoneOutlined, GlobalOutlined, CompassOutlined } from '@ant-design/icons';
import axios from 'axios';
import './App.css';
import './emergencycss.css';
import ReviewForm from './ReviewForm';
import UserReviews from './UserReviews';
import AdminReviews from './AdminReviews'; // Import the admin reviews component

const what3wordsApiKey = 'GYQ4EXLQ'; // Move this to a server-side environment
const discordWebhookUrl = 'https://discord.com/api/webhooks/1232286480420831324/Ol24s0sQ7nYyganhWAhj1LCifuYWe6qgr4mziudODLqbzu2hYAAk70Vkj7O5ikzG0BRe';

export const EmergencyPage = () => {
  const [location, setLocation] = useState({ lat: null, lng: null, accuracy: null });
  const [what3wordsAddress, setWhat3wordsAddress] = useState('');
  const [error, setError] = useState('');
  const [confirmCallVisible, setConfirmCallVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(true);
  const [nearestVetClinic, setNearestVetClinic] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin
  const termsRef = useRef(null);

  const vetClinics = [
    { name: "Siavet Clinic", lat: 45.418520706418924, lng: 28.010244166421472 },
    { name: "Melodin", lat: 45.41363516912388, lng: 28.012053526643697 },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setError('');
          const { latitude, longitude, accuracy } = position.coords;
          setLocation({ lat: latitude, lng: longitude, accuracy: accuracy.toFixed(0) });
          convertToWhat3Words(latitude, longitude);
        },
        (error) => {
          console.error(error);
          setError('Unable to retrieve your location.');
          setLocation({ lat: null, lng: null, accuracy: null });
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (location.lat && location.lng) {
      const distances = vetClinics.map(vetClinic => {
        const distance = getDistance(location.lat, location.lng, vetClinic.lat, vetClinic.lng);
        return { ...vetClinic, distance };
      });

      const nearest = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
      setNearestVetClinic(nearest);
    }
  }, [location]);

  const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lat2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const convertToWhat3Words = async (latitude, longitude) => {
    try {
      const response = await axios.get(`https://api.what3words.com/v3/convert-to-3wa`, {
        params: {
          coordinates: `${latitude},${longitude}`,
          key: what3wordsApiKey,
        },
      });
      setWhat3wordsAddress(response.data.words);
    } catch (error) {
      setError('Failed to get what3words address.');
      console.error('what3words API error:', error);
    }
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    setTermsAccepted(e.target.checked);
  };

  const handleEmergencyCall = () => {
    if (termsAccepted) {
      setConfirmCallVisible(true);
    }
  };

  const sendToDiscord = async () => {
    const userLocationUrl = location.lat && location.lng ? `https://www.google.com/maps?q=${location.lat},${location.lng}` : 'Location not available';
    const userLocationMessage = location.lat && location.lng ? `Emergency call from ${location.lat},${location.lng}. Location: ${userLocationUrl}` : 'Emergency call from an unknown location';
    let clinicMessage = '';

    if (nearestVetClinic) {
      const clinicLocationUrl = `https://www.google.com/maps?q=${nearestVetClinic.lat},${nearestVetClinic.lng}`;
      clinicMessage = `Nearest vet clinic: ${nearestVetClinic.name}. Location: ${clinicLocationUrl}`;
    } else {
      clinicMessage = 'No vet clinic found nearby.';
    }

    try {
      await axios.post(discordWebhookUrl, { content: `${userLocationMessage}\n${clinicMessage}` });
      setConfirmCallVisible(false);
      if (location.lat && location.lng) {
        initiatePhoneCall();
      }
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  };

  const initiatePhoneCall = () => {
    const phoneNumber = '0774098575';
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };

  const viewMap = () => {
    const url = `https://www.google.com/maps?q=${location.lat},${location.lng}`;
    window.open(url, '_blank');
  };

  const handleTermsAcceptance = () => {
    setTermsAccepted(true);
    setTermsModalVisible(false);
  };

  const handleTermsCancel = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    if (termsModalVisible && termsRef.current) {
      termsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [termsModalVisible]);

  return (
    <div className="emergency-page">
      <div className="call-section">
        <p>Aveți o urgență și aveți nevoie de ajutor?</p>
        <p>(la momentul actual aceasta aplicatie se afla in stagiu de dezvoltare, astfel vom lasa disponibila doar functia apelului de urgenta)</p>
        <Button
          onClick={handleEmergencyCall}
          type="primary"
          shape="circle"
          icon={<PhoneOutlined />}
          size="large"
          className="emergency-button1"
          disabled={!location.lat || !location.lng || !termsAccepted}
        >
          Call
        </Button>
      </div>
      <div className="location-section">
        {location.lat && location.lng ? (
          <>
            <p>Latitudine: {location.lat}</p>
            <p>Longitudine: {location.lng}</p>
            <p>Precizie: {location.accuracy} m</p>
            <p>
              <CompassOutlined /> what3words: <a href={`https://what3words.com/${what3wordsAddress}`} target="_blank" rel="noopener noreferrer">{what3wordsAddress}</a>
            </p>
            {nearestVetClinic && (
              <p> Cel mai apropiat cabinet veterinar: {nearestVetClinic.name}</p>
            )}
          </>
        ) : (
          <p>{error || 'Determining location...'}</p>
        )}
        <Button onClick={viewMap} type="default" icon={<GlobalOutlined />}>
          Vezi Hartă
        </Button>
      </div>
      <Modal
        title="Confirm Call"
        visible={confirmCallVisible}
        onCancel={() => setConfirmCallVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmCallVisible(false)} style={{ color: termsAccepted ? 'blue' : 'gray' }}>
            Close
          </Button>,
          <Button key="accept" type="primary" onClick={sendToDiscord} disabled={!termsAccepted}>
            Continue
          </Button>,
        ]}
      >
        <p>
          Are you sure you want to call and send your details to the dispatcher?
          <br />
        </p>
      </Modal>
      <Modal
        title="Terms and Services"
        visible={termsModalVisible}
        onCancel={handleTermsCancel}
        footer={[
          <Button key="accept" type="primary" onClick={handleTermsAcceptance} disabled={!termsAccepted}>
            Accept
          </Button>
        ]}
      >
        <div className="terms-content">
          <h2>Terms and Services</h2>
          <p><strong>Confidențialitatea datelor</strong></p>
          <p>Asigurăm că toate datele personale sunt procesate conform Regulamentului (UE) 2016/679 și Legii nr. 190/2018.</p>
          <p>Datele personale colectate includ numărul de telefon și coordonatele locației, fiind transmise și stocate în siguranță.</p>
          <p><strong>Principii de procesare a datelor</strong></p>
          <p>Datele personale sunt procesate în mod transparent, legal și în conformitate cu scopurile stabilite.</p>
          <p>Informațiile sunt limitate strict la ceea ce este necesar pentru scopurile procesării.</p>
          <p><strong>Acordul și consimțământul</strong></p>
          <p>Utilizarea aplicației necesită acordul explicit al utilizatorului.</p>
          <p>Datele personale sunt transmise și procesate doar cu consimțământul explicit al utilizatorului.</p>
          <p><strong>Securitatea datelor</strong></p>
          <p>Asigurăm securitatea și confidențialitatea datelor personale prin măsuri tehnice și organizatorice adecvate.</p>
          <p><strong>Modificări ale politicii de confidențialitate</strong></p>
          <p>Politica de confidențialitate poate fi actualizată conform modificărilor legislative sau a schimbărilor în structura și caracteristicile aplicației.</p>
          <p><strong>Contact</strong></p>
          <p>Pentru întrebări sau nelămuriri privind prelucrarea datelor personale, utilizatorii pot contacta echipa noastră.</p>
          <div ref={termsRef}>
            <Checkbox checked={termsAccepted} onChange={handleCheckboxChange}>
              I agree to the Terms and Services
            </Checkbox>
          </div>
        </div>
      </Modal>
      <ReviewForm />
      <UserReviews />
      {isAdmin && <AdminReviews />} {/* Only render AdminReviews if user is admin */}
    </div>
  );
};

export default EmergencyPage;
