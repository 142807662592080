import React, { useState, useContext } from 'react';
import { Layout, Menu, Drawer, Button, Card } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './TopBar.css';
import logo from './logo.png';
/*import ProgramariButton from './ProgramariButton'; // Import your new button component */

const { Header } = Layout;

export default function TopBar() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  const handleProgramariClick = () => {
    navigate('/programari');
  };
  
  return (
    <Header className="top-bar">
      <div className="menu-icon" onClick={toggleDrawer}>
        <MenuOutlined />
      </div>
      <img src={logo} alt="Logo" className="logo-image" />
      <Drawer
        title="Menu"
        placement="left"
        onClick={toggleDrawer}
        onClose={toggleDrawer}
        visible={isDrawerOpen}
      >
        <div className="auth-container">
          {!user ? (
            <Card className="account-card" style={{ borderRadius: '10px', marginBottom: '20px' }}>
              <p>You are not logged in yet</p>
              <Button type="primary" onClick={() => navigate('/login')} block>
                Login
              </Button>
              <Button type="default" onClick={() => navigate('/register')} block>
                Register
              </Button>
              <Button type="link" onClick={() => {
                sessionStorage.setItem('guest', 'true');
                logout(); // To clear any previous user state
                navigate('/');
              }} block>
                Continue as Guest
              </Button>
            </Card>
          ) : (
            <Card className="account-card" style={{ borderRadius: '10px', marginBottom: '20px' }}>
              {user.guest ? (
                <p>Viewing as Guest</p>
              ) : (
                <p>Welcome, {user.email}</p>
              )}
              <Button type="primary" onClick={logout} block>
                Log Out
              </Button>
            </Card>
          )}

        </div>
        <Menu mode="inline">
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/about-us">About</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/contact">Contact</Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/faq">FAQ</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/chat">ChatTest</Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/emergency">Emergency</Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/mypet">My pet</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Header>
  );
}
