import React, { useState } from 'react';
import { Card, Button, Form, Input, Checkbox, Modal } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import siavetImage from './img/siavet.jpg'; // Add the correct path to your Siavet clinic image
import melodinImage from './img/melodin.png'; // Add the correct path to your Melodin clinic image

const clinics = [
  {
    name: 'Siavet - Dr. Silvia Aftene',
    image: siavetImage,
    description: 'Siavet provides comprehensive veterinary services with a focus on personalized care for each pet.',
    email: '6nfism@gmail.com'
  },
  {
    name: 'Melodin',
    image: melodinImage,
    description: 'Melodin offers a wide range of medical services for pets, ensuring their health and well-being.',
    email: 'joker.ghostxyz@gmail.com'
  }
];

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const CardContent = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const ClinicPreview = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedClinic, setSelectedClinic] = useState(null);

  const showModal = (clinic) => {
    setSelectedClinic(clinic);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        const email = selectedClinic.email;
        axios.post(`http://2.57.122.223:5000/api/send-email`, { ...values, email })
          .then(response => {
            console.log(response.data);
            Modal.success({
              title: 'Success',
              content: 'Programarea a fost comunicata cabinetului.',
            });
            form.resetFields();
            setIsModalVisible(false);
          })
          .catch(error => {
            console.error('There was an error sending the email!', error);
            Modal.error({
              title: 'Error',
              content: 'A fost o problemă la trimiterea emailului. Te rugăm să încerci din nou.',
            });
          });
      })
      .catch(errorInfo => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
      {clinics.map(clinic => (
        <StyledCard
          hoverable
          style={{ width: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          cover={<img alt={clinic.name} src={clinic.image} />}
          key={clinic.name}
        >
          <CardContent>
            <Card.Meta
              title={clinic.name}
              description={clinic.description}
            />
            <p>Working Hours: 9:00 AM - 5:00 PM</p>
          </CardContent>
          <ButtonContainer>
            <Button type="primary" block onClick={() => showModal(clinic)}>
              Programeaza-te acum
            </Button>
          </ButtonContainer>
        </StyledCard>
      ))}

      <Modal
        title="Formular Programare"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="ownerName"
            label="Nume proprietar *"
            rules={[{ required: true, message: 'Te rog să completezi numele proprietarului' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="animalName"
            label="Nume animal *"
            rules={[{ required: true, message: 'Te rog să completezi numele animalului' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="animalBirthDate"
            label="Data nașterii animal *"
            rules={[{ required: true, message: 'Te rog să completezi data nașterii animalului' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="allergies"
            label="Alergii *"
            rules={[{ required: true, message: 'Te rog să completezi alergiile' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email *"
            rules={[{ required: true, type: 'email', message: 'Te rog să completezi emailul' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="species"
            label="Specie *"
            rules={[{ required: true, message: 'Te rog să completezi specia' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Nr. Telefon *"
            rules={[{ required: true, message: 'Te rog să completezi numărul de telefon' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="breed"
            label="Rasa *"
            rules={[{ required: true, message: 'Te rog să completezi rasa' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="animalMedicalHistory"
            label="Istoric medical animal *"
            rules={[{ required: true, message: 'Te rog să completezi istoricul medical' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="problemDescription"
            label="Descriere problemă *"
            rules={[{ required: true, message: 'Te rog să completezi descrierea problemei' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dataAccuracy"
            valuePropName="checked"
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Trebuie să accepți această declarație') }]}
          >
            <Checkbox>
              Declar că datele furnizate sunt corecte și îmi asum responsabilitatea pentru animalul adus în grija.
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="dataProcessing"
            valuePropName="checked"
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Trebuie să accepți această declarație') }]}
          >
            <Checkbox>
              Declar că îmi dau acordul pentru prelucrarea, stocarea și utilizarea datelor cu caracter personal în vederea înregistrării animalului aflat în grija mea, în baza de date CANIA sau în unele E-Mailuri și documente oficiale CANIA. Prin bifarea acestui câmp sunteți de acord cu termenii și condițiile impuse de CANIA care v-au fost comunicate la înființarea contului.
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ClinicPreview;
